var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.loader.get)?_c('i',{staticClass:"fa fa-spinner fa-5x fa-spin text-success text-center"}):_vm._e(),_c('form',{staticClass:"form-horizontal",attrs:{"id":"form-tipo-movimento","role":"form"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-body',[_c('h4',[_vm._v("Banco")]),_c('div',{staticClass:"form-row form-group",attrs:{"role":"group"}},[_c('div',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"tipo_nome_id"}},[_c('b-badge',{staticClass:"field-status",attrs:{"data-name":"Total","variant":_vm.banco.banco_nome !== null && _vm.banco.banco_nome !== ''
                          ? 'success'
                          : 'danger'}},[_vm._v(" "+_vm._s("*Nome")+" ")])],1),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.banco.banco_nome),expression:"banco.banco_nome"}],staticClass:"form-control text-leftt",attrs:{"type":"text","placeholder":"digite o Nome do banco","id":"banco"},domProps:{"value":(_vm.banco.banco_nome)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.banco, "banco_nome", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"tipo_banco_codigo"}},[_c('b-badge',{staticClass:"field-status",attrs:{"data-name":"Total","variant":_vm.banco.banco_codigo !== null && _vm.banco.banco_codigo !== ''
                          ? 'success'
                          : 'danger'}},[_vm._v(" "+_vm._s("*Codigo")+" ")])],1),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.banco.banco_codigo),expression:"banco.banco_codigo"}],staticClass:"form-control text-left",attrs:{"type":"text","placeholder":"digite o codigo do banco","id":"codigo"},domProps:{"value":(_vm.banco.banco_codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.banco, "banco_codigo", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"tipo_banco_digito"}},[_c('b-badge',{staticClass:"field-status",attrs:{"data-name":"Total","variant":_vm.banco.banco_digito !== null && _vm.banco.banco_digito !== ''
                          ? 'success'
                          : 'danger'}},[_vm._v(" "+_vm._s("*Digito")+" ")])],1),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.banco.banco_digito),expression:"banco.banco_digito"}],staticClass:"form-control text-left",attrs:{"type":"text","placeholder":"digite o digito","id":"digito"},domProps:{"value":(_vm.banco.banco_digito)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.banco, "banco_digito", $event.target.value)}}})])])])]),_c('div',{staticClass:"form-row form-group",attrs:{"role":"group"}},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"tipo_banco_num"}},[_c('b-badge',{staticClass:"field-status",attrs:{"data-name":"Total","variant":_vm.banco.banco_maxnossoNum !== null &&
                        _vm.banco.banco_maxnossoNum !== ''
                          ? 'success'
                          : 'danger'}},[_vm._v(" "+_vm._s("*Nosso numero")+" ")])],1),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.banco.banco_maxnossoNum),expression:"banco.banco_maxnossoNum"}],staticClass:"form-control text-left",attrs:{"type":"text","placeholder":"digite o Numero maximo","id":"num"},domProps:{"value":(_vm.banco.banco_maxnossoNum)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.banco, "banco_maxnossoNum", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"tipo_banco_orient"}},[_c('b-badge',{staticClass:"field-status",attrs:{"data-name":"Total","variant":_vm.banco.banco_orientacoes !== null &&
                        _vm.banco.banco_orientacoes !== ''
                          ? 'success'
                          : 'danger'}},[_vm._v(" "+_vm._s("*Orientações")+" ")])],1),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.banco.banco_orientacoes),expression:"banco.banco_orientacoes"}],staticClass:"form-control text-left",attrs:{"type":"text","placeholder":"Orientações","id":"orient"},domProps:{"value":(_vm.banco.banco_orientacoes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.banco, "banco_orientacoes", $event.target.value)}}})])])])])])],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-md-12 mt-10"},[_c('button',{staticClass:"btn btn-light",on:{"click":_vm.setPost}},[_vm._v("Gravar")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }